<template>
    <div class="main-table__data">
        <div class="image-block">
            <img :src="LogoHelper.getLogo(columnData.logoUrl)" alt="">
        </div>

        <div class="pr-3">
            <h5 class="heading5 d-flex align-items-center">
                <a
                    rel="noopener"
                    target="_blank"
                    v-if="addData.companyType === searchTypes.STARTUPS"
                    :href="`/startups/${columnData.id}`"
                >{{ columnData.name }}
                </a>
                <a
                    rel="noopener"
                    target="_blank"
                    v-if="addData.companyType === searchTypes.ECOSYSTEMS"
                    :href="`/ecosystems/${columnData.id}`"
                >{{ columnData.name }}</a>
                <a
                    rel="noopener"
                    target="_blank"
                    v-if="addData.companyType === searchTypes.ENTERPRISES &&  columnData.id > 0"
                    :href="`/enterprises/${columnData.id}`"
                >{{ columnData.name }}</a>
                <span v-if="addData.companyType === searchTypes.ENTERPRISES && columnData.id === null">{{ columnData.name  }}</span>

                <span v-if="columnData.has_account" class="badge badge__active" :id="'has-account-badge-' + columnData.id">
                    <b-tooltip :target="'has-account-badge-' + columnData.id" placement="right" variant="light" title="Active on SwitchPitch"></b-tooltip>
                </span>

                <span v-if="addData.companyType === searchTypes.ECOSYSTEMS && columnData.type !== null" :class="columnData.type">{{columnData.type_title}}</span>

                <span v-if="columnData.op_status === 'was_acquired'" class="label">Acquired</span>
                <span v-if="columnData.op_status === 'closed'" class="label label--inactive">Inactive</span>
            </h5>

            <div>
                <span class="line-clamp">{{columnData.shortDescription}}</span>
                
                <div class="main-table__data__more-link mt-3">
                    <a v-if="columnRawData.status !== 'Invite sent'" @click="getCompanyDetails" class="link link--more" :id="'details-link-'+ addData.companyType + '-' + rowId">More Info</a>
                    <a v-if="!$store.getters.isEventLimited && addData.companyType === searchTypes.STARTUPS && addData.fromSearch === true" @click="$emit('action', {action: 'get-similar', rowId: columnData.id})" class="link link--similar">Similar Startups</a>

                    <template v-if="addData.trackerId">
                        <a v-show="addData.companyType === searchTypes.STARTUPS && trackersCount" :id="'trackers-link-'+rowId" @click="getCompanyDetails" class="link counter">{{ trackersCountLabel }}</a>
                    </template>

                    <favorite-toggle
                        v-if="addData.companyType === searchTypes.ECOSYSTEMS && !addData.trackerType"
                        :id="columnData.id"
                        :state="columnData.is_favorited"
                    ></favorite-toggle>
                </div>
            </div>
        </div>

        <div class="main-table__connection" v-if="(addData.companyType === searchTypes.ECOSYSTEMS || addData.companyType === searchTypes.ENTERPRISES) && typeof columnData.connect_status !== 'undefined' ">
            <template v-if="columnData.connect_status === 'new'">
                <span class="main-table__connection__sent">Invite Sent</span>
            </template>

            <template v-else-if="columnData.connect_status === 'accepted'">
                <span class="main-table__connection__success">Connected</span>
            </template>

            <template v-else-if="columnData.connect_status === 'rejected'">
                <span class="main-table__connection__rejected">Invite Rejected</span>
            </template>

            <template v-else-if="columnData.connect_status === 'request-sent'">
                <span class="main-table__connection__sent">Request Sent</span>
            </template>

            <template v-else-if="columnData.connect_status === 'request-rejected'">
                <span class="main-table__connection__sent">Request Rejected</span>
            </template>

            <template v-else-if="!$store.getters.isEnterprise || $store.state.user.settings.enable_connections">
                <a class="link" @click="$emit('action', {action: 'invite', rowId: columnData.id})">
                    <template v-if="addData.companyType === searchTypes.ENTERPRISES && (!columnData.has_account || $store.getters.isStartup)">
                        + Request Connection
                    </template>
                    <template v-else>
                        + Connect
                    </template>
                </a>
            </template>
        </div>
        
        <template v-if="'hasTrackers' in columnData && columnData.hasTrackers">
            <a :id="'trackers-link-'+rowId" class="counter-wrapper">
                <span @click="getCompanyDetails" class="counter">
                    <template v-if="trackersCount > 0">{{trackersCount}}</template>
                    <template v-else>+</template>
                </span>
            </a>
        </template>

        <b-popover ref="details" :target="'details-link-'+ addData.companyType + '-' + rowId">
            <div class="popover__content" v-click-outside="closeDetailsPopover">
                <header class="popover__content__header">
                    <div class="popover__content__side">
                        <img class="main-table__logo" :src="LogoHelper.getLogo(columnData.logoUrl)" alt="">
                    </div>
                    <div>
                        <h4 class="heading4">
                            <a v-if="addData.companyType === searchTypes.STARTUPS" target="_blank" :href="'/startups/' + columnData.id">{{ columnData.name }}</a>
                            <a v-if="addData.companyType === searchTypes.ECOSYSTEMS" target="_blank" :href="'/ecosystems/' + columnData.id">{{ columnData.name }}</a>
                        </h4>
                        <p>{{startupDetails.description}}</p>
                    </div>
                </header>

                <div class="popover__content__body" v-if="startupDetails.website">
                    <div class="popover__content__side">
                        <h6 class="heading6">Website:</h6>
                    </div>
                    <div>
                        <p>
                            <a :href="startupDetails.website" target="_blank">{{startupDetails.website}}</a>
                        </p>
                    </div>
                </div>

                <template v-if="addData.companyType === searchTypes.STARTUPS">
                    <div class="popover__content__body">
                        <div class="popover__content__side">
                            <h6 class="heading6">Trackers:</h6>
                        </div>
                        <div>
                            <p>
                                <span v-for="(tracker, index) in startupDetails.trackers" :key="tracker.id">
                                    <a :href="'/trackers/' + tracker.id" target="_blank">{{ tracker.name }}</a>
                                    <template v-if="index < startupDetails.trackers.length - 1">, </template>
                                </span>
                            </p>
                        </div>
                    </div>

                    <div v-if="$store.getters.isEnterprise" class="popover__content__body">
                        <div class="popover__content__side">
                            <h6 class="heading6">Tags:</h6>
                        </div>
                        <div class="tags-list">
                            <span v-for="tag in startupDetails.tags" :key="tag.id" class="tags-list__tag" :title="tag.name">
                                <a @click="tagClicked(tag)" :id="'tag-' + columnData.id + '-' + tag.id">{{ tag.name }}</a>
                                <b-tooltip :target="'tag-' + columnData.id + '-' + tag.id" placement="right" variant="light" :title="tag.description"></b-tooltip>
                                <span @click="removeTag(tag.id)" class="tags-list__tag__close"></span>
                            </span>

                            <a v-if="!showAddTags" @click.stop="showAddTags = true; getTagsList()" class="tags-list__add">+ Add</a>

                            <multiselect v-if="showAddTags"
                                v-model="addedTags"
                                :multiple="true"
                                :options="actualTagsList"
                                select-label=""
                                deselect-label=""
                                label="name"
                                track-by="name"
                                class="multiselect-mid pl-2"
                            >
                            </multiselect>

                            <template v-if="showAddTags">
                                <div class="d-flex justify-content-end w-100">
                                    <a @click="showAddTags = false" class="btn btn-cancel">Cancel</a>
                                    <a v-if="addedTags.length" @click="addTags" class="btn btn-primary ml-3">+ Add Tags</a>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
            </div>
        </b-popover>

        <b-popover v-if="(columnData.hasTrackers || addData.trackerId)" ref="trackersPopover" :target="'trackers-link-'+rowId" placement="bottomright">
            <div class="popover__content popover__content--secondary" v-click-outside="closeTrackersPopover">
                <header class="popover__content__header align-items-end flex-wrap flex-sm-nowrap">
                    <h4 class="heading4">Add to tracker</h4>

                    <a @click="$emit('action', {action: 'add-tracker', rowId: columnData.id})" v-on:click="closeTrackersPopover" class="link">Create New</a>
                </header>

                <div class="popover__content__body flex-column">
                    <multiselect
                        class="multiselect multiselect-mid"
                        v-model="addedTracker"
                        :options="actualTrackersList"
                        select-label=""
                        deselect-label=""
                        label="name"
                        track-by="name"
                    >
                        <template slot="option" slot-scope="props">
                            <span v-b-popover.hover.top="props.option.created_by + ': ' + props.option.created_at">{{props.option.name}}</span>
                        </template>
                    </multiselect>

                    <h4 class="heading4 mt-4" v-if="startupDetails.trackers.length">
                        On {{startupDetails.trackers.length}} {{stringHelper.getNoun('tracker', startupDetails.trackers.length)}}
                    </h4>

                    <ul class="rows-list">
                        <li v-for="tracker in startupDetails.trackers" :key="tracker.id">
                            <h5 class="heading5 heading5--secondary"><a :href="'/trackers/' + tracker.id" target="_blank">{{ tracker.name }}</a></h5>
                            <a v-if="tracker.type !== 'public'" @click="removeFromTracker(tracker.id)" class="remove-action">Remove</a>
                        </li>
                        
                        <!--li>
                            <h5 class="heading5 heading5--secondary"><a href="#">Tracker Name</a></h5>
                            <a class="link ml-auto"><b-button v-b-modal.add-info-modal> + Add</b-button></a>
                        </li-->
                    </ul>

                </div>
            </div>
        </b-popover>
        
        <b-modal v-if="false" id="add-info-modal" modal-class="add-info-modal modal-w-456 modal-funnel-card-popup" title="Add Info For Tracker">
            <div class="form__item mb-3">
                <h6 class="heading6 mb-1">Status</h6>
                
                <select class="multiselect__input selectbox selectbox-mid mb-0">
                    <option>POC Proposed</option>
                    <option>POC Proposed</option>
                    <option>POC Proposed</option>
                </select>
            </div>
            
            <div class="form__item mb-3">
                <h6 class="heading6 mb-1">Timelines</h6>
                <date-picker class="mx-datepicker-range mb-3" format = "MM/DD/YYYY" v-model="date" placeholder="Article date*"></date-picker>    
            
                <date-picker class="mx-datepicker-range" format = "MM/DD/YYYY" v-model="date" placeholder="Article date*"></date-picker>    
            </div>
            
            <div class="form__item mb-3">
                <h6 class="heading6 mb-1">Notes <i>(optional)</i></h6>
                <textarea data-v-20902f96="" class="form-control--mid form-control"></textarea>
            </div>

            <template #modal-footer>
                <b-button class="btn btn-primary btn-lg" >Add To Tracker</b-button>
            </template>
        </b-modal>
        
    </div>
</template>

<script>

import FavoriteToggle from "../../components/FavoriteToggle";
import startups from "../../services/startups.service";
import ecosystems from "../../services/ecosystems.service";
import multiselect from 'vue-multiselect'
import tags from '../../services/tags.service'
import trackersCompanies from "../../services/trackers-companies.service";
import startupEnterprisesService from "../../services/startup/enterprises.service";
import ecosystemEnterprisesService from "../../services/ecosystem/enterprises.service";
import DatePicker from "vue2-datepicker";
import searchTypes from "../../constants/searchTypes";

export default {
    props: {
        columnData: {
            'name': String,
            'logoUrl': String,
            'trackersCount': Number,
            'shortDescription': String,
            'hasTrackers': {
                type: String,
                default: false
            },
            'op_status': String,
        },

        addData: Object,
        rowId: Number,
        columnRawData: Object
    },
    components: {
        multiselect,
        FavoriteToggle,
        DatePicker
    },
    data() {
        return {
            startupDetails: {
                description: '',
                tags: [],
                trackers: [],
            },

            tagsList: [],
            addedTags: [],
            addedTracker: null,
            trackersCount: 0,
            actualTrackersList: [],

            showAddTags: false,
            updating: false,
        }
    },

    watch: {
        addedTracker() {
            if (this.addedTracker !== null) {
                this.addToTracker()
            }
        },
        columnData: {
            deep: true,
            immediate: true,
            handler() {
                if (typeof this.columnData.trackersCount !== "undefined") {
                    this.trackersCount = this.columnData.trackersCount

                    if (this.addData.trackerId) {
                        // exclude current tracker from count
                        this.trackersCount--
                    }
                }
            }
        },
        'startupDetails.trackers': function (startupTrackers) {
            if (startupTrackers) {
                let addedToTrackers = JSON.parse(JSON.stringify(startupTrackers))

                if (this.addData.trackerId) {
                    // exclude current tracker from list
                    addedToTrackers.push({id: this.addData.trackerId})
                }

                if ('trackersList' in this.addData) {
                    this.actualTrackersList = this.addData.trackersList.filter(tracker => !addedToTrackers.some(startupTracker => startupTracker.id === parseInt(tracker.id)))
                }
            }
        },
    },
    computed: {
        searchTypes() {
            return searchTypes
        },

        logoUrl() {
            if (this.columnData.logoUrl) {
                return this.columnData.logoUrl
            }
            else {
                return require('../../images/' + this.defaults.startupLogo)
            }
        },

        trackersCountLabel() {
            return 'On ' + this.trackersCount + ' other ' + this.stringHelper.getNoun('tracker', this.trackersCount)
        },

        actualTagsList() {
            return this.tagsList.filter(tag => !this.startupDetails.tags.some(existedTag => existedTag.id === tag.id))
        },

        enterprisesService() {
            if (this.$store.getters.isStartup) {
                return startupEnterprisesService
            } else {
                return ecosystemEnterprisesService
            }
        }
    },

    methods: {
        getCompanyDetails() {
            if (this.addData.companyType === searchTypes.ECOSYSTEMS) {
                ecosystems.getDetails(this.columnData.id).then(response => {
                    this.startupDetails = response.data
                })
            } else if (this.addData.companyType === searchTypes.ENTERPRISES) {
                this.enterprisesService.getDetails(this.columnData.id).then(response => {
                    this.startupDetails = response.data
                })
            } else {
                startups.getDetails(this.columnData.id).then(response => {
                    if (this.addData.trackerId) {
                        // exclude current tracker from list
                        response.data.trackers = response.data.trackers.filter(tracker => tracker.id !== this.addData.trackerId)
                    }

                    this.startupDetails = response.data;

                    if ('trackers' in this.startupDetails) {
                        this.trackersCount = this.startupDetails.trackers.length
                    }
                })
            }
        },

        closeDetailsPopover() {
            this.cancelAddTags()
            this.$refs.details.$emit('close')
        },

        showTrackersDetails() {
            startups.getDetails(this.columnData.id).then(response => {
                this.startupDetails = response.data
            })
        },

        closeTrackersPopover() {
            this.$refs.trackersPopover.$emit('close')
        },

        cancelAddTags() {
            this.showAddTags = false
            this.addedTags = []
        },

        getTagsList() {
            if (!this.tagsList.length) {
                tags.getList().then(response => {
                    this.tagsList = response.data
                })
            }
        },

        addTags() {
            let addedTags = this.addedTags.map(item => item.id);
            let startups = [this.columnData.id];

            tags.addTags(startups, addedTags).then(() => {
                this.cancelAddTags()
                this.getCompanyDetails()
            })
        },

        removeTag(tagId) {
            if (!this.updating) {
                this.updating = true

                let removedTags = [tagId]
                let startups = [this.columnData.id]
                tags.removeTags(startups, removedTags).then(() => {
                    this.getCompanyDetails()
                }).finally(() => {
                    this.updating = false
                })
            }
        },

        addToTracker() {
            if (confirm('Add startup to selected tracker?')) {
                let addedTrackers = [this.addedTracker.id]
                let startups = [this.columnData.id]
                trackersCompanies.addCompanies(addedTrackers, startups).then(() => {
                    this.getCompanyDetails()
                    this.addedTracker = null
                })
            } else {
                this.addedTracker = null
            }
        },

        removeFromTracker(trackerId) {
            if (!this.updating) {
                this.updating = true

                trackersCompanies.removeCompanies(trackerId, [this.columnData.id]).then(() => {
                    this.getCompanyDetails()
                }).finally(() => {
                    this.updating = false
                })
            }
        },

        tagClicked(tag) {
            if (typeof this.addData.fromSearch !== "undefined") {
                this.$emit('action', {action: 'tag-clicked', tagName: tag.name})
            }
            else {
                this.$router.push('/startups?tag=' + tag.name)
            }
        },
    }
}
</script>

<style scoped>
    .popover {
        + .popover {
            padding: 5px 5px 5px 10px;
            width: 140px;
            font-size: 12px;
        }
    }
</style>
